@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none;
  /* Standard syntax */
}

.modal-content {
  border-radius: 30px;
}

.lk-text-red {
  transition: all 0.3s;
  color: #ee5253 !important;
}

.lk-bg-red {
  background-color: #ee5253 !important;
}

.lk-text-black {
  color: #172126;
}

.lk-bg-black {
  background-color: #172126;
}

.lk-text-light-black {
  color: #54575e;
}

.lk-bg-light-black {
  background-color: #54575e;
}

.lk-text-light-pink {
  color: #ffdee2;
}

.lk-bg-light-pink {
  background-color: #ffdee2;
}

.lk-text-white-1 {
  color: #fffafa;
}

.lk-bg-white-1 {
  background-color: #fffafa;
}

.lk-text-white-2 {
  color: #fcfcfc;
}

.lk-bg-white-2 {
  background-color: #fcfcfc;
}

.lk-red-button {
  background-color: #dc3545 !important;
  border-radius: 10px !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.lk-red-button:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #fff;
}

.lk-red-button:disabled {
  background-color: #c8cacc !important;
  color: #040607 !important;
  cursor: default !important;
}

.lk-transition {
  transition: all 0.4s;
}

.lk-cursor-pointer {
  cursor: pointer;
}

.lk-rounded {
  border-radius: 15px !important;
}

.lk_is_copied {
  background: #8eeca0;
  background: linear-gradient(to right, #8eeca0 0%, #72dfa9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8eeca0', endColorstr='#72dfa9', GradientType=1);
}

.lk-error {
  background-color: #fcd4d4;
  padding: 10px 20px;
  border-radius: 0 20px 20px 20px;
  position: relative;
  top: 10px;
  color: #ee5253;
}

/* App defaults */
.App {
  background-color: #fcfcfc;
  min-height: 200vh;
}

/* Offers */
.lk-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #dc3545;
  flex-wrap: wrap;
  gap: 10px;
  text-decoration: none;
}

.lk-discount p {
  font-family: 'Poppins', inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
}

.lk-discount span {
  background: #020202;
  border-radius: 20.8523px;
  text-decoration: none;
  font-family: 'Poppins', inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 12.4283px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #f2f2f2;
  margin-left: 15px;
  padding: 0 10px;
  display: block;
  text-align: center;
}

/* Cookie popup */
.popUp-cookie h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #524758;
}

.popUp-cookie h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  color: #524759;
}

.popUp-cookie p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
}

.popUp-cookie p span {
  color: rgba(0, 0, 0, 0.8);
}

.popUp-cookie button {
  background: #161616;
  border-radius: 12.5926px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15.7407px;
  line-height: 24px;
  color: #ffffff;
}

.popUp-cookie button:hover {
  color: #fff;
  background: #161616;
}

.popUp-cookie button:focus {
  outline: none;
  box-shadow: none;
}

/* Navbar */
.lk-primary-navbar {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: transform 150ms ease-in-out;
}

.lk-primary-navbar.nav-hidden {
  transform: translateY(-100%);
}

.lk-primary-navbar .lk-navbar-nav a,
.lk-primary-navbar .lk-navbar-nav-auth a:not(.lk-red-button) {
  color: #172126 !important;
}

.lk-primary-navbar .lk-navbar-nav a.active {
  color: #ee5253 !important;
}

.offcanvas {
  background: rgba(255, 255, 255, 0.95) !important;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .offcanvas {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}

.offcanvas-header button {
  margin-left: auto;
}

.offcanvas-body a {
  color: #172126;
  font-size: 18px;
}

.offcanvas-body .lk-navbar-nav a:hover {
  color: #ea5153;
}

.lk-tablet-menu {
  position: fixed;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s;
}

.lk-tablet-menu > div {
  height: 500px;
  border: 2px solid #e3e3e3;
  border-radius: 15px 0 15px 15px;
  padding: 35px;
  margin-right: 4rem;
  background-color: #fff;
}

.lk-tablet-menu > div .lk-navbar-nav a {
  padding-left: 0;
  color: #172126;
}

.lk-tablet-menu > div .lk-navbar-nav-auth a {
  color: #172126;
}

/* Section-1 */
.section-1 h1,
.section-1 h2 {
  font-size: 60px;
}

.lk-url-wrap {
  background-color: #f1f5f8;
  box-shadow: 0 3px 16px 0 rgb(225 231 235 / 50%);
  padding: 15px 25px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.lk-url-wrap .lk-url-circles,
.lk-url-wrap .lk-url-arrows {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.lk-url-wrap .lk-url-arrows {
  margin-left: 30px;
  margin-right: 30px;
}

.lk-url-wrap .lk-url-arrows li {
  background-color: #f2f2f2;
  padding: 5px 15px;
  box-shadow: 0 3px 16px rgb(195 195 195 / 38%);
  border-radius: 10px;
  line-height: 2;
}

.lk-url-wrap .lk-url-arrows li svg {
  color: #707070;
}

.lk-url-wrap .lk-url-arrows li:nth-child(2) {
  margin-left: 10px;
}

.lk-url-wrap .lk-url-arrows li:last-child {
  margin-left: 40px;
}

.lk-url-wrap .lk-url-circles li {
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: #ee5253;
  box-shadow: 0 3px 16px 0 rgb(225 231 235 / 50%);
}

.lk-url-wrap .lk-url-circles li:nth-child(1) {
  background-color: #f16058;
}

.lk-url-wrap .lk-url-circles li:nth-child(2) {
  background-color: #fabf3f;
  margin: 0 10px;
}

.lk-url-wrap .lk-url-circles li:nth-child(3) {
  background-color: #5aca41;
}

.lk-url-path {
  background-color: #fff;
  border-radius: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;
  transition: all 0.3s;
}

.lk-url-path .bullet {
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a8a8a8;
}

.lk-url-path .url {
  display: block;
  width: 100%;
  text-decoration: none;
  color: #040607;
  cursor: default;
}

.lk-url-path .url span:nth-child(2) {
  color: #a9a9a9;
  opacity: 0.3;
  left: 70px;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lk-url-path .character {
  position: absolute;
  left: 34%;
  width: 300px;
  top: -5px;
  transition: all 0.3s;
}

/* Section-2 */
.section-2 .container-lg {
  background-image: url(https://cdn.1cdn.app/application/LUMBERJACK/2208240627_pattern-small.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 550px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.section-2 .container-lg.is-open {
  background-size: auto;
}

.section-2 .lk-form-box {
  background-color: #fff;
  padding: 29px 33px;
  border-radius: 20px 0 20px 0;
  box-shadow: 2px 78px 45px -29px rgba(255, 13, 13, 0.06);
}

.section-2 .lk-form-box .lh-form-wrapper {
  background-color: #fcf5f5;
  border-radius: 10px;
  padding: 20px;
  display: flex;
}

.section-2 .lk-form-box .lh-form-wrapper.active {
  background-color: #f5fcf5;
}

.section-2 .lk-form-box .lh-form-wrapper input {
  width: 100%;
  margin: 10px;
  background-color: transparent;
  border: none;
}

.section-2 .lk-form-box .lh-form-wrapper input:focus {
  border: none;
  outline: none;
}

.section-2 .lk-form-box .lh-form-wrapper input::placeholder {
  color: #d8c0c0;
}

.section-2 .lk-form-box .lh-form-wrapper.active input::placeholder {
  color: #c7d8c0;
}

.section-2 .lk-form-box .lk-qr-code {
  display: flex;
  flex-flow: column;
  margin-bottom: 40px;
}

.section-2 .lk-form-box .lk-qr-code img {
  width: 190px;
  margin: auto;
}

.section-2 .lk-form-box .lk-qr-code button {
  width: 190px;
  margin: auto;
  margin-top: 20px;
}

.section-2 .lk_is_copied_bg {
  background-color: #f5fcf5;
}

.section-3 .lk-cs-card {
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 20px 0 20px 0;
  min-height: 200px;
  text-align: center;
  padding: 37px 15px;
  transition: all 0.3s;
}

.section-3 .lk-cs-card:hover {
  background-color: #fff;
}

.section-3 .lk-cs-card.first {
  margin-top: 8rem;
}

.section-3 .lk-cs-card.second {
  margin-top: 3rem;
}

.section-3 .lk-cs-card p:nth-child(2) {
  font-size: 16px;
  color: #000;
  font-weight: 300;
}

.section-3 .lk-cs-card p:nth-child(3) {
  text-align: center;
  color: #a8a2a2;
  font-size: 14px;
  font-weight: 200;
  line-height: 25px;
}

.section-3 .lk-cs-card p:nth-child(4) {
  color: #a8a2a2;
  font-size: 12px;
}

.section-4 {
  background-image: url('https://cdn.1cdn.app/application/LUMBERJACK/2208240702_section4Bg.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 7rem;
}

.section-4 .animates {
  z-index: 0;
  position: relative;
}

.section-4 .animates .img-1 {
  position: absolute;
  left: 0;
  top: -85px;
}

.section-4 .animates .img-2 {
  position: absolute;
  right: 0;
  top: -85px;
}

.section-4 .lk-tab {
  z-index: 2;
}

.section-4 .lk-tab .nav {
  width: 52%;
  margin: auto;
  box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16), 0px 13px 43px 0px rgba(231, 231, 231, 1);
  border-radius: 25px;
  padding: 10px;
  justify-content: space-evenly;
  position: relative;
  margin-bottom: 2rem;
  background-color: #f6f6f6;
}

.section-4 .lk-tab .nav li {
  border: 0;
}

.section-4 .lk-tab .nav li button {
  border: none;
  border-radius: 25px;
  color: #363636;
  transition: all 0.3s;
  font-size: 14px;
}

.section-4 .lk-tab .nav li button.active {
  background-color: #dc3545;
  color: #fff;
}

.section-4 .lk-tab .tab-content {
  width: 90%;
  margin: auto;
  z-index: 2;
  background-color: rgba(255, 123, 123, 0.05);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 100px 0 100px 0;
  min-height: 350px;
}

.section-4 .lk-tab .tab-content > div {
  padding: 60px 100px;
}

.section-4 .animates_2 {
  z-index: 0;
  position: relative;
}

.section-4 .animates_2 .img-1 {
  position: absolute;
  left: -25px;
  top: -150px;
  transform: scaleX(-1) rotate(-52deg) !important;
  -webkit-transform: scaleX(-1) rotate(-52deg) !important;
  -ms-transform: scaleX(-1) rotate(-52deg) !important;
}

.section-4 .animates_2 .img-2 {
  position: absolute;
  right: 0;
  top: -130px;
  transform: rotate(130deg) !important;
  -webkit-transform: rotate(130deg) !important;
  -ms-transform: rotate(130deg) !important;
}

.section-4 .lk-tab .tab-content > div .lk-quota-card .title {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1rem;
}

.section-4 .lk-tab .tab-content > div .lk-quota-card .iconic-i {
  background-color: #ffdee2;
  border-radius: 50%;
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.section-4 .lk-tab .tab-content > div .lk-quota-card .content {
  margin-left: 20px;
}

.section-4 .lk-tab .tab-content > div .lk-quota-card .title h3 {
  font-size: 22px;
  font-weight: 300;
  color: #ee5253;
  font-weight: 600;
}

.section-4 .lk-tab .tab-content > div .lk-quota-card .content p {
  color: #a8a8a8;
  font-size: 14px;
  line-height: 22px;
}

.section-4 .lk-tab .tab-content > div .lk-quota-card .content ul li {
  color: #a8a8a8;
  font-size: 14px;
}

.section-4 .lk-feature-card {
  min-height: 200px;
  background-color: #fff;
  border-radius: 20px 0 20px 0;
  padding: 30px 20px 10px 20px;
  transition: all 0.3s;
  color: #232323;
  display: flex;
  flex-flow: column;
}

.section-4 .lk-feature-card:hover,
.section-4 .lk-feature-card:focus {
  background-color: #ee5253;
  color: #fff;
}

.section-4 .lk-feature-card .num {
  height: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
}

.section-4 .lk-feature-card:hover .num {
  height: auto;
  margin-bottom: 4rem;
  visibility: visible;
}

.section-4 .lk-feature-card .desc {
  height: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
}

.section-4 .lk-feature-card:hover .desc {
  height: auto;
  visibility: visible;
}

.section-4 .lk-feature-card p {
  font-size: 14px;
  line-height: 24px;
}

.section-4 .lk-feature-card svg {
  color: #ee5253;
  transition: all 0.3s;
  cursor: pointer;
}

.section-4 .lk-feature-card:hover svg,
.section-4 .lk-feature-card:focus svg {
  color: #fff;
}

.section-5 .lk-stats {
  background-color: #fff;
  min-height: 90px;
  padding: 20px;
  position: relative;
  top: -45%;
  box-shadow: 0px 69px 32px 0px rgba(248, 111, 111, 0.03);
}

.section-5 .lk-stats .iconic-i {
  background-color: #ffdee2;
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-5 .lk-stats .desc p {
  font-size: 12px;
  color: #363636;
}

.section-5 .lk-stats .desc span {
  font-size: 18px;
  color: #363636;
}

.section-6 h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 90px;
  text-align: center;
  color: #ee5253;
  margin-bottom: 12px;
}

.section-6 p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: #54575e;
  margin-bottom: 18px;
}

.section-6 .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.plans-page .explore-plans {
  background: #f9eeee;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 2rem;
}

.plans-page .explore-plans h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #172126;
  margin-bottom: 15px;
}

.plans-page .explore-plans p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #54575e;
  margin-bottom: 30px;
}

.plans-page .explore-plans img {
  position: relative;
  bottom: -40px;
}

.plans-section .title-intro p {
  width: 100%;
  margin: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #54575e;
}

.plans-section .title-intro .best-deal {
  display: inline-block;
  padding: 10px 15px;
  background: #fff0f0;
  border-radius: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ee5253;
  margin-top: 20px;
  margin-bottom: 10px;
}

.plans-section .currency-selector h6 {
  color: #172126;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.plans-section .currency-selector .form-check label {
  color: #172126;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.plans-section .currency-selector .form-check-input:checked {
  background-color: #ee5253;
  border-color: #ee5253;
}

.plans-section .currency-selector .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 13, 93, 0.315);
}

.plans-section .title-intro .arrow-point {
  transform: translate(-50px);
}

.plans-section .lk-subs-type {
  width: 250px;
  background-color: #fff;
  margin: auto;
  padding: 20px;
  box-shadow: 0px 12px 20px -16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.plans-section .lk-subs-type ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.plans-section .lk-subs-type ul li:not(:nth-child(2)) {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-weight: 400;
}

.plans-section .lk-subs-type ul li:nth-child(2) {
  color: #e3e3e3;
}

.plans-section .lk-subs-type ul li.active {
  border-bottom: 2px solid #ee5253;
  color: #ee5253;
}

.plans-section .animate {
  top: -150px;
  left: 70px;
}

.plans-section .lk-offers {
  margin-top: 1rem;
}

.plans-section .lk-offers .lk-offers-card {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 10px 3px 6px 0px rgba(26, 26, 26, 0.02);
  transition: all 0.3s;
}

.plans-section .lk-offers .lk-offers-card:hover {
  background-color: #ea5153;
}

.plans-section .lk-offers .lk-offers-card .title {
  margin-bottom: 20px;
}

.plans-section .lk-offers .lk-offers-card .title .badge {
  background-color: #ea5153;
  border-radius: 25px;
}

.plans-section .lk-offers .lk-offers-card .title h2 {
  font-size: 22px;
  color: #470000;
  font-weight: 600;
  margin-bottom: 0;
}

.plans-section .lk-offers .lk-offers-card:hover .title h2 {
  color: #fff;
}

.plans-section .lk-offers .lk-offers-card:hover .title .badge {
  background-color: #ffffff;
  color: #ea5153;
}

.plans-section .lk-offers .lk-offers-card .title .badge svg {
  fill: #ffffff;
}

.plans-section .lk-offers .lk-offers-card:hover .title .badge svg {
  fill: #ea5153;
}

.plans-section .lk-offers .lk-offers-card .price {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.plans-section .lk-offers .lk-offers-card .price h3 {
  color: #470000;
  font-size: 32px;
}

.plans-section .lk-offers .lk-offers-card:hover .price h3 {
  color: #fff;
}

.plans-section .lk-offers .lk-offers-card .price .offer {
  margin-left: 10px;
  font-size: 18px;
  color: #000;
  transition: all 0.1s ease-in;
}

.plans-section .lk-offers .lk-offers-card:hover .price .offer {
  color: #fff;
  font-size: 28px;
}

.plans-section .lk-offers .lk-offers-card .price .discount {
  font-size: 28px;
  margin-left: 10px;
}

.plans-section .lk-offers .lk-offers-card .price .discount del {
  color: rgba(71, 0, 0, 0.25);
}

.plans-section .lk-offers .lk-offers-card:hover .price .discount del {
  color: #fff;
}

.plans-section .lk-offers #free .lk-offers-card .desc p,
.plans-section .lk-offers .lk-offers-card .desc p.is-monthly {
  color: #470000;
  font-size: 16px;
  position: relative;
}

.plans-section .lk-offers #free .lk-offers-card .desc p svg,
.plans-section .lk-offers .lk-offers-card .desc p.is-monthly svg {
  color: #ee5253;
}

.plans-section .lk-offers #free .lk-offers-card:hover .desc p,
.plans-section .lk-offers #free .lk-offers-card:hover .desc p svg {
  color: #ffffff;
}

.plans-section .lk-offers #free .lk-offers-card .desc p svg path {
  fill: #ee5253;
}

.plans-section .lk-offers #free .lk-offers-card:hover .desc p svg path {
  fill: #fff;
}

.plans-section .lk-offers .lk-offers-card .desc p {
  color: #7d4949;
  font-size: 14px;
  text-align: left;
}

.plans-section .lk-offers .lk-offers-card:hover .desc p {
  color: #fff;
}

.plans-section .lk-offers .lk-offers-card .offers {
  margin-bottom: 2rem;
}

.plans-section .lk-offers .lk-offers-card .offers ul {
  margin-bottom: 0;
}

.plans-section .lk-offers .lk-offers-card .offers ul li {
  display: flex;
  align-items: center;
  min-height: 45px;
  text-align: left;
}

.plans-section .lk-offers .lk-offers-card .offers ul li span {
  color: #5e5454;
}

.plans-section .lk-offers .lk-offers-card:hover .offers ul li span {
  color: #fff;
}

.plans-section .lk-offers .lk-offers-card .offers ul li:not(:last-child) {
  margin-bottom: 10px;
}

.plans-section .lk-offers .lk-offers-card .offers ul li div {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffbfbf;
  border-radius: 50%;
  margin-right: 10px;
}

.plans-section .lk-offers .lk-offers-card .offers ul li div svg {
  color: #fff;
}

.plans-section .lk-offers .lk-offers-card:hover .action a {
  background-color: #fff !important;
  color: #ea5153 !important;
}

.plans-section .lk-offers-nav button,
.section-4 .lk-offers-nav button,
.section-7 .lk-offers-nav button {
  font-weight: 400;
}

.plans-section .lk-offers-nav button:not(:disabled),
.section-4 .lk-offers-nav button:not(:disabled),
.section-7 .lk-offers-nav button:not(:disabled) {
  color: #ee5253;
}

.plans-section .lk-offers-nav button:focus,
.section-4 .lk-offers-nav button:focus,
.section-7 .lk-offers-nav button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.section-7 {
  background-image: url('https://cdn.1cdn.app/application/LUMBERJACK/2208240702_section4Bg.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 7rem;
}

.section-7 .lk-customers-review {
  border-radius: 5px 40px 5px 40px;
  background-color: #fdfdfd;
  margin-top: 2rem;
}

.section-7 .lk-review-item {
  padding: 50px 160px;
}

.section-7 .lk-review-item .swiper-slide {
  display: flex;
  flex-flow: column;
  min-height: 295px;
}

.section-7 .lk-customers-review img {
  position: absolute;
}

.section-7 .lk-customers-review img:first-child {
  top: -45px;
  left: -45px;
}

.section-7 .lk-customers-review img:last-child {
  bottom: 0px;
  right: -45px;
  transform: rotate(140deg);
  -webkit-transform: rotate(140deg);
  -ms-transform: rotate(140deg);
}

.section-7 .lk-review-item blockquote {
  color: #172126;
  margin-bottom: auto;
  font-size: 22px;
  line-height: 25px;
}

.section-7 .lk-review-item h3 {
  color: #172126;
  font-weight: 300;
  font-size: 16px;
}

.section-7 .lk-review-item small {
  font-size: 12px;
  color: #363636;
}

.section-7 .lk-site-note-box {
  background-color: #dc3545;
  border-radius: 50px 5px 50px 5px;
  padding: 48px 54px;
  color: #fff;
}

.section-7 .lk-site-note-box blockquote {
  font-size: 28px;
}

/* .section-8 {
  background-color: #fcfcfc;
} */

.section-8 .lk-accordion-item {
  color: #4a1c1c;
  padding: 25px 0;
}

.section-8 .lk-accordion-item:not(:last-child) {
  border-bottom: 1px solid #e3e3e3;
}

.section-8 .lk-accordion-item p {
  color: #a79595;
  font-size: 14px;
}

footer {
  background-color: #f8f8f8;
  border-radius: 70px 70px 0 0;
  padding-bottom: 2rem;
}

footer .site {
  padding: 70px 0 40px 0;
}

footer p:not(:first-child) {
  color: #353232;
  font-size: 14px;
}

footer ul li a {
  color: #000000;
  text-decoration: none;
  transition: all 0.3s;
}

footer ul li a:hover {
  color: #ea5153;
}

footer small {
  color: #353232;
  font-size: 12px;
}

.lk-error-page {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

/* .lk-error-page img {
  width: 50%;
}

.lk-error-page.server img {
  width: 40%;
}

.lk-error-page.maintenance img {
  width: 40%;
} */

.lk-error-page h2 {
  color: #524758;
  font-size: 4vw;
}

.lk-error-page p {
  color: #524758;
}

.secure-page {
  background-color: #fcfcfc;
}

.secure-page img.flipped {
  width: 340px;
}

.secure-page .secure-box {
  background-color: #fff;
  border-radius: 60px 10px 60px 10px;
  padding: 60px 30px;
  box-shadow: 0px 4px 24px 0px rgba(219, 219, 219, 0.25);
  margin-bottom: 32px;
}

.secure-page .secure-box h2 {
  color: #9a9a9a;
  font-size: 45px;
}

.secure-page .secure-box p {
  color: #c2c2c2;
  font-size: 20px;
}

.secure-page .secure-box .input-wrapper {
  background-color: #fcf5f5;
  padding: 25px;
  border-radius: 20px 5px 20px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.secure-page .secure-box .input-wrapper img:last-child {
  cursor: pointer;
}

.secure-page .secure-box .input-wrapper input {
  width: 90%;
  background-color: transparent;
  border: none;
}

.secure-page .secure-box .input-wrapper input:focus {
  outline: none;
}

.secure-page .secure-box .input-wrapper input::placeholder {
  color: #d8c0c0;
}

.secure-page .secure-box .action {
  display: flex;
  justify-content: flex-end;
}

.secure-page .secure-box .action button {
  padding: 10px 20px;
}

.secure-page .lum-box {
  border-radius: 10px 60px 10px 60px;
  background-color: #ee5253;
  padding: 28px;
  text-align: center;
}

.secure-page .lum-box a {
  text-decoration: none;
  color: #fff;
  display: block;
}

.secure-page .lum-box a h5 {
  margin-bottom: 0;
}

.scroll-top {
  width: 40px;
  height: 40px;
  background-color: #ea5153;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 10px;
  border-radius: 50%;
  cursor: pointer;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 999;
}

.scroll-top.active {
  visibility: visible;
}

.scroll-top svg {
  color: #fff;
}

.contact-us form .form-control {
  background-color: #f8f8f8;
  border-radius: 15px;
  border: none;
  padding: 15px;
}

.contact-us form .content-wrapper {
  border-radius: 15px;
  background-color: #f8f8f8;
}

.contact-us form .form-control::placeholder {
  font-size: 14px;
  color: #b5b5b5;
}

.contact-us form .content-wrapper.active {
  background-color: #fff;
}

.contact-us form .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fe8686;
  outline: 0;
  box-shadow: none;
}

.contact-us form .form-text {
  font-size: 12px;
  color: #c2c2c2 !important;
}

.privacy-pop-up {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 999;
}

.plans-page .intro h1 {
  margin-bottom: 20px;
}

.plans-page .intro p {
  color: #54575e;
  width: 50%;
}

.plans-page .switcher ul {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
}

.plans-page .switcher ul li {
  width: 160px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plans-page .switcher ul li.active {
  background-color: #ee5253;
  box-shadow: 0 5px 7px 0 rgba(82, 67, 194, 0.23);
  border-radius: 30px;
  color: #fff;
}

.plans-page .lk-offers-card {
  background-color: #f8f8f8;
  text-align: center;
  padding: 10px 4px;
  border-radius: 15px;
}

.plans-page .lk-offers-card .position-sticky {
  background-color: #f8f8f8;
  top: 0;
}

.plans-page .lk-offers-card:hover,
.plans-page .lk-offers-card:hover .position-sticky {
  background-color: #ee5253;
  transition: all 0.3s;
  color: #fff;
}

.plans-page .lk-offers-card:hover .title,
.plans-page .lk-offers-card:hover .price h2 {
  color: #fff;
}

.plans-page .lk-offers-card .title {
  color: #ee5253;
  height: 70px;
}

.plans-page .lk-offers-card .action a {
  background-color: #ee5253;
  color: #fff;
}

.plans-page .lk-offers-card:hover .action a {
  background-color: #fff;
  color: #444;
}

.plans-page .lk-offers-card .price {
  height: 90px;
}

.plans-page .lk-offers-card .offers ul li svg.check {
  fill: #dd5d59;
  stroke: #dd5d59;
}

.plans-page .lk-offers-card:hover .offers ul li svg.check {
  fill: #fff;
  stroke: #fff;
}

.plans-page .lk-offers-card .price h2 {
  color: #470000;
}

.plans-page .plans-compare .spaceBox {
  height: 60px;
}

.plans-page .plans-compare .grouped-titles li b {
  color: #ee5253;
  /* margin-bottom: 10px; */
}

.plans-page .plans-compare .grouped-titles li ul {
  margin-bottom: 20px;
}

.plans-page .plans-compare .grouped-titles li ul li {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #232323;
}

.plans-page .plans-compare .grouped-value {
  margin-bottom: 45px;
}

.plans-page .plans-compare .lk-offers-card:hover .grouped-value .grouped-value-item svg.check {
  fill: #fff;
}

.plans-page .plans-compare .lk-offers-card:hover .grouped-value .grouped-value-item svg.not-check {
  fill: #000;
}

.plans-page .plans-compare .grouped-value-item {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #232323;
  height: 26px;
}

.collapsed-plans-compare {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 20px;
}

.collapsed-plans-compare h6 {
  color: #54575e;
}

.collapsed-plans-compare ul.collapse-list {
  list-style-type: none;
  padding-left: 0;
}

.collapsed-plans-compare ul.collapse-list li {
  color: #54575e;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: auto;
}

.demo-page {
  min-height: calc(100vh - 400px);
}

.demo-page .video-intro h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  /* line-height: 60px; */
  color: #172126;
  margin-bottom: 15px;
}

.demo-page .video-intro h1 span {
  color: #ee5253;
}

.demo-page .video-intro p {
  color: #54575e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.demo-page .video-intro .actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.demo-page .video-intro .actions a {
  color: #ee5253;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}

.demo-page .video-intro .actions .btn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ee5253;
  display: flex;
  align-items: center;
}

.demo-page .rounded-bg {
  background: #f8f8f8;
  border-radius: 0px 100px;
  padding: 82px 0;
  margin-bottom: 5rem;
}

.demo-page .series-title h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #ee5253;
  margin-bottom: 15px;
}

.demo-page .series-title p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #54575e;
}

.demo-page .video-box h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #172126;
}

.demo-page .video-box p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #54575e;
}

.demo-page .sec-2 h2 {
  color: #172126;
  font-family: Poppins;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 125% */
}

.demo-page .sec-2 p {
  color: #54575e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.demo-page .sec-2 a {
  color: #ee5253;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  text-decoration: none;
}

.contact-us .title-area,
.terms-page .title-area {
  background: #f8eeee;
  padding: 25px;
}

.contact-us .title-area h1,
.terms-page .title-area h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #ee5253;
  margin-bottom: 15px;
}

.contact-us .title-area p,
.terms-page .title-area p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #54575e;
  margin-bottom: 0;
}

.news-page .sec-1 {
  margin-bottom: 40px;
}

.news-page .sec-1 .underlay {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 75%);
          clip-path: polygon(0 0, 100% 0, 100% 75%, 0 75%);
  background-color: #f8eeee;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.news-page .sec-1 .news-item span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ee5253;
  margin-bottom: 5px;
}

.news-page .sec-1 .news-item h1,
.news-page .sec-1 .news-item h2,
.news-page .sec-1 .news-item h3,
.news-page .sec-1 .news-item h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #172126;
  margin-bottom: 15px;
}

.news-page .sec-1 .news-item p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #54575e;
  margin-bottom: 30px;
}

.news-page .sec-1 .news-item a {
  border: 1px solid #172126;
  border-radius: 20px;
  text-decoration: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #172126;
  padding: 14px 22px;
  display: inline-block;
}

.news-page .sec-1 .news-item-img-placeholder {
  /* max-height: 450px; */
  background: #ffcccc;
  box-shadow: 0px 14px 34px rgba(238, 82, 83, 0.2);
  border-radius: 20px;
}

.news-page .sec-1 .news-sec-1-pag li {
  width: 10px;
  height: 10px;
  background: #f2a6a6;
  border-radius: 100%;
  transition: all 0.3s;
}

.news-page .sec-1 .news-sec-1-pag li.active {
  width: 38px;
  background: #ee5253;
  border-radius: 100px;
}

.news-page .sec-2 .title-area h4,
.news-page .sec-3 .title-area h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #172126;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 30px;
}

.news-page .sec-2 .title-area h4::after {
  content: '';
  width: 100px;
  height: 7px;
  background: #ee5253;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.news-page .sec-2 .news-item {
  position: relative;
  background: #ffcccc;
  border-radius: 20px;
  /* height: 400px; */
}

.news-page .sec-2 .news-item img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  max-height: 364px;
  object-fit: cover;
}

.news-page .sec-2 .news-item .content-overlay {
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0.05) 0%, #403f3f 100%);
  border-radius: 0px 0px 20px 20px;
  padding: 18px 20px;
  width: 100%;
  color: #fff;
}

.news-page .sec-2 .news-item .content-overlay h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
}

.news-page .sec-2 .news-item .content-overlay span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.news-page .sec-3 .title-area h4::after {
  content: '';
  width: 100px;
  height: 7px;
  background: #ee5253;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.news-page .sec-3 .news-item .image-box {
  background: #ffcccc;
  border-radius: 10px;
  height: 200px;
  margin-bottom: 15px;
}

.news-page .sec-3 .news-item .content-overlay h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #172126;
  margin-bottom: 12px;
}

.news-page .sec-3 .news-item .content-overlay span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ee5253;
}

.news-page.detail-page .cover-area {
  /* height: 350px;
  max-height: 350px; */
  background: #fde5e5;
  border-radius: 20px 20px 0px 0px;
}

.news-page.detail-page .cover-area img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  /* object-fit: cover; */
}

.news-page.detail-page .content-area {
  background: #fcfafa;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  top: -20px;
}

.news-page.detail-page .content-area > span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ee5253;
  margin-bottom: 12px;
}

.news-page.detail-page .title-area h1 {
  color: #000;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 30px;
}

.news-page.detail-page .title-area h1::after {
  content: '';
  width: 100px;
  height: 7px;
  background: #ee5253;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.news-page.detail-page h3 {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.news-page.detail-page .content p {
  color: #54575e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}
.news-page.detail-page .content li {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #54575e;
}

.news-page.detail-page .content li:not(:last-child) {
  margin-bottom: 24px;
}

.news-page.detail-page .share .btn {
  padding: 10px 16px 10px 12px;
  background: #ffffff;
  border-radius: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ee5253 !important;
}

.news-page.detail-page .share .btn:focus {
  outline: none;
  box-shadow: none;
}

.terms-lg-nav .nav-item a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #87898e;
  cursor: pointer;
}

.terms-lg-nav .nav-item a.active {
  background-color: transparent;
  color: #ee5253;
}

.grecaptcha-badge {
  opacity: 0;
}

.banned-page {
  background: #ee5253;
  padding-top: 84px;
  padding-bottom: 84px;
}

.banned-page .row {
  padding: 52px 62px;
}

.banned-page .content {
  background-color: #ededed;
}

.banned-page h1 {
  color: #435061;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 120% */
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}

.banned-page h1 svg {
  flex-shrink: 0;
}

.banned-page p {
  white-space: pre-line;
  color: #435061;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.banned-page .warning-box {
  background: #ee5253;
  color: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
}

.banned-page ul {
  list-style: none;
  padding: 0;
}

.banned-page ul li,
.banned-page p span {
  color: #d97706;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.banned-page small {
  color: #6b7280;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  margin-bottom: 10px;
  display: block;
}

.warning-page {
  margin-top: 52px;
  margin-bottom: 52px;
}

.warning-page .container {
  padding: 52px 62px;
  border-radius: 8px;
  background: #ededed;
}

.warning-page h1 {
  color: #435061;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 120% */
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}

.warning-page h1 svg {
  flex-shrink: 0;
}

.warning-page p {
  white-space: pre-line;
  color: #435061;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.warning-page p span {
  color: #435061;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.warning-page small {
  color: #6b7280;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  margin-bottom: 10px;
  display: block;
}

.warning-page .left {
  border-left: 2px solid #f59e0b;
  padding-left: 25px;
}

/* Responsive */
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .section-5 .lk-stats .desc p {
    font-size: 9px;
  }

  .section-5 .lk-stats .desc span {
    font-size: 15px;
  }

  .section-3 p.w-50,
  .plans-section p.w-25,
  .section-7 p.w-50,
  .section-8 p.w-50 {
    width: 100% !important;
  }

  .section-7 .lk-site-note-box {
    padding: 25px 25px;
  }

  .section-7 .lk-review-item {
    padding: 25px 15px;
  }

  footer {
    border-radius: 25px 25px 0 0;
    padding: 20px 0;
  }

  .plans-section .title-intro p {
    width: 100%;
  }

  .plans-page .explore-plans img {
    bottom: unset;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .lk-error {
    padding: 10px 20px;
    position: relative;
    top: 10px;
    color: #ee5253;
    line-height: 30px;
    font-size: 12px;
  }

  .lk-url-path .url span:nth-child(2) {
    display: none;
  }

  .section-1 h1 {
    font-size: 60px;
  }

  .section-2 .container-lg {
    background-image: none;
  }

  .section-4 .lk-tab .tab-content {
    width: 100%;
  }

  .lk-url-wrap {
    display: block;
    padding: 0;
  }

  .lk-url-wrap .lk-url-arrows {
    margin-left: 0;
  }

  /* .lk-url-path .url span:nth-child(2) {
      display: none;
    } */
  .lk-url-path .character {
    display: none;
    position: absolute;
    /* left: 77%; */
    width: 150px;
    top: 4px;
  }

  .section-2 .lk-form-box .lh-form-wrapper img {
    width: 20px;
  }

  .section-2 .lk-form-box .lh-form-wrapper {
    flex-flow: column;
  }

  #plans img {
    width: 60%;
  }

  .section-2 .lk-form-extra h3 {
    text-align: center;
  }

  .section-3 .lk-cs-card.first,
  .section-3 .lk-cs-card.second {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .section-4 .lk-tab .tab-content > div {
    padding: 30px 40px;
  }

  .section-4 .animates,
  .section-4 .animates_2 {
    display: none;
  }

  .section-4 .lk-tab .nav {
    width: 100%;
  }

  .section-4 .lk-tab .tab-content {
    min-height: auto;
  }

  .section-5 .lk-stats .desc p {
    font-size: 9px;
  }

  .section-5 .lk-stats .desc span {
    font-size: 15px;
  }

  .section-3 p.w-50,
  .plans-section p.w-25,
  .section-7 p.w-50,
  .section-8 p.w-50 {
    width: 100% !important;
  }

  .plans-section .lk-offers {
    margin-top: 2rem;
  }

  .plans-section .animate {
    display: none;
  }

  .section-6 h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 2rem;
  }

  .section-7 .lk-site-note-box {
    padding: 25px 25px;
  }

  .section-7 .lk-review-item {
    padding: 25px 15px;
  }

  footer {
    border-radius: 25px 25px 0 0;
    padding: 20px 0;
  }

  .secure-page .secure-box h2 {
    font-size: 30px;
  }

  .secure-page .secure-box p {
    font-size: 16px;
  }

  .secure-page .secure-box .input-wrapper {
    padding: 25px 10px;
  }

  .secure-page .secure-box .input-wrapper img {
    width: 20px;
  }

  .secure-page .secure-box .input-wrapper input {
    width: 80%;
  }

  .fp-modal .front .fp-footer {
    position: unset;
  }

  .plans-page .switcher ul li {
    width: 140px;
    height: 45px;
  }

  .plans-page .intro p {
    width: 100%;
  }

  .plans-page .plans-compare .col {
    flex: none;
  }

  .plans-page .lk-offers-card .price {
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .plans-page .lk-offers-card .price h2 {
    margin-bottom: 0;
  }

  .plans-page .lk-offers-card .title {
    text-align: left;
  }

  .plans-switch p {
    width: 100% !important;
  }

  .lk-discount {
    padding: 5px;
  }

  .lk-discount p {
    font-size: 12px;
  }

  .plans-section .title-intro p {
    width: 100%;
  }

  .plans-page .explore-plans img {
    bottom: unset;
  }

  .news-page .sec-3 .news-item {
    display: flex;
    gap: 10px;
  }

  .news-page .sec-3 .news-item .image-box {
    width: 100%;
    height: 136px;
  }

  .news-page .sec-3 .news-item .content-overlay h5 {
    font-size: 20px;
  }

  .banned-page .row,
  .warning-page .container {
    padding: 20px;
  }

  .demo-page .sec-2.fist img {
    height: 298px !important;
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .lk-url-wrap {
    display: block;
  }

  .lk-url-wrap .lk-url-arrows {
    margin-left: 0;
  }

  #plans img {
    width: 50%;
  }

  .section-2 .container-lg {
    background-image: none;
  }

  .section-2 .lk-form-extra h3 {
    text-align: center;
  }

  .section-3 .lk-cs-card.first,
  .section-3 .lk-cs-card.second {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .section-4 .lk-tab .tab-content {
    min-height: auto;
  }

  .section-4 .lk-tab .tab-content > div {
    padding: 30px 40px;
  }

  .section-4 .animates,
  .section-4 .animates_2 {
    display: none;
  }

  .section-4 .lk-tab .nav {
    width: 90%;
  }

  .section-7 .lk-review-item {
    padding: 30px 40px;
  }

  .plans-section .lk-offers {
    margin-top: 2rem;
  }

  .plans-section .animate {
    display: none;
  }

  .fp-modal .front .fp-footer {
    position: unset;
  }

  .panel.is_back .front {
    display: none;
  }

  .panel.is_front .front {
    display: flex;
  }

  .plans-section .title-intro p {
    width: 100%;
  }

  .plans-page .explore-plans img {
    bottom: unset;
  }

  .news-page .sec-3 .news-item {
    display: flex;
    gap: 10px;
  }

  .news-page .sec-3 .news-item .image-box {
    width: 50%;
    height: 136px;
  }

  .demo-page .sec-2.fist img {
    height: 298px !important;
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .w-25 {
    width: 25% !important;
  }

  .lk-url-wrap .lk-url-arrows {
    margin-left: 0;
  }

  .lk-url-path .character {
    position: absolute;
    left: 42%;
    width: 250px;
    top: -10px;
  }

  #plans img {
    width: 40%;
  }

  .section-2 .container-lg {
    background-image: none;
  }

  .section-2 .lk-form-extra h3 {
    text-align: center;
  }

  .section-4 .animates,
  .section-4 .animates_2 {
    display: none;
  }

  .section-4 .lk-tab .nav {
    width: 90%;
  }

  .section-4 .lk-tab .tab-content {
    min-height: auto;
  }

  .section-4 .lk-tab .tab-content > div {
    padding: 30px 50px;
  }

  .plans-section .lk-offers {
    margin-top: 2rem;
  }

  .plans-section .animate {
    display: none;
  }

  .section-7 .lk-review-item {
    padding: 30px 40px;
  }

  footer {
    padding: 30px 0;
  }

  .secure-page img.flipped {
    width: 260px;
    margin-bottom: 2rem;
  }

  .plans-page .explore-plans img {
    bottom: unset;
  }

  .demo-page .sec-2.fist img {
    height: 298px !important;
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lk-url-path .character {
    left: 40%;
  }

  #plans img {
    width: 70%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .section-7 .lk-review-item .swiper-slide {
    min-height: 210px;
  }
}

.consent-manager {
  position: fixed;
  bottom: -100;
  width: 100%;
  height: 0;
  background-color: #fff;
  transition: height 1s;
  z-index: 1022;
  padding: 30px 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.consent-manager.open {
  height: max-content;
  z-index: 1022;
  bottom: 0;
}

body.consent-manager-open {
  overflow: hidden;
}

body.consent-manager-open::after {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1021;
}

.consent-manager.open .title {
  font-size: xx-large;
  text-align: center;
  margin-bottom: 16px;
}

.consent-manager.open p {
  width: 75%;
  text-align: center;
  color: #374151;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  word-wrap: break-word;
  margin: auto;
}

.consent-manager.open .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 12px;
}

.consent-manager.open .buttons button {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 8px;
}

.consent-manager.open .buttons button:nth-child(1) {
  border-radius: 12px;
  background: #ee5253;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
}

.consent-manager.open .buttons button:nth-child(2) {
  border-radius: 12px;
  border: 1px solid #ee5253;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #ee5253;
  background-color: #fff;
}

.consent-manager.open .buttons button:nth-child(3) {
  background-color: transparent;
  color: #ee5253;
}

.consent-manager.open .checklists {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.consent-manager.open .checklists div label {
  margin-left: 6px;
}

.consent-manager.open .checklists div input {
  border-radius: 4px;
}

.consent-manager.open .checklists div input:checked {
  border-radius: 4px;
  accent-color: #ee5253;
}

/* Styles for larger screens */
@media (max-width: 768px) {
  .consent-manager.open p {
    width: 100%;
  }

  .consent-manager.open .checklists {
    margin-top: 20px;
  }

  .consent-manager.open .buttons {
    order: 2;
  }
}

